import React, {useEffect, useState} from 'react';
import Contact from "./contact";
import {Link, useLocation} from "react-router-dom";

const Dotation = () => {

    const [dotation, setDotation] = useState("nothing")
    const location = useLocation()

    useEffect(() => {
        if(location.state === null || location.state.dotation === null)
            window.location.href="/";
        else
            setDotation(location.state.dotation.toLowerCase())
    }, []);

    return (
        <>
            <div id="dotationStrate1" className={`strate bottom ${dotation}`}>

                {dotation ==="nothing" &&
                    <>
                        <h2>Dommage</h2>
                        <div className={"dotation_name"}>
                            ça ne sera pas pour cette fois...
                        </div>
                        <p>N’oublie pas de consulter ton compte pour vérifier le nombre de jetons qu’il te reste suite au téléchargement de ta preuve d’achat, tu pourras peut-être retenter ta chance dès maintenant !</p>
                        <Link className={"cta red"} to={"/mon-compte"}>Mon compte</Link>
                    </>
                }

                {dotation === "photo" &&
                    <>
                        <h2>Félicitations !</h2>
                        <div className={"dotation_name"}>
                            Tu as gagné* un set LEGO® <br className={"d-lg-none"}/>31147 - L’Appareil Photo Rétro !
                        </div>
                    </>
                }
                {dotation === "flower" &&
                    <>
                        <h2>Félicitations !</h2>
                        <div className={"dotation_name"}>
                            Tu as gagné* un set LEGO® <br className={"d-lg-none"}/>10328 - Le Bouquet de Roses !
                        </div>
                    </>
                }
                {dotation === "concorde" &&
                    <>
                        <h2>Félicitations !</h2>
                        <div className={"dotation_name"}>
                            Tu as gagné* un set LEGO® <br className={"d-lg-none"}/>10318 - Le Concorde !
                        </div>
                    </>
                }

                {dotation !=="nothing" &&
                    <div className={"mention"}>
                        *Sous réserve que ta participation soit valide.<br/>
                        Si elle est valide, tu recevras, sous 5 jours ouvrés, un e-mail te confirmant ta dotation.<br/>
                        Dans le cas contraire, si elle n’est pas valide, tu recevras un e-mail t’expliquant la raison.
                    </div>
                }

            </div>
            <Contact/>
        </>
    );
};

export default Dotation;
