// src/UserContext.js
import React, {createContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

// Création du contexte utilisateur
export const UserContext = createContext();

// Fournisseur du contexte utilisateur
export const UserProvider = ({ children }) => {

    const [user, setUser] = useState({
        connected : false,
        init : false
    });

    const login = (jwt) => {

        localStorage.setItem("jwt", jwt)

        setUser({
            init : true,
            connected: true,
            jwt : jwt,
        })
    };

    const logout = () => {
        localStorage.removeItem("jwt")
        
        setUser({
            init : true,
            connected: false,
            jwt : null
        })
    };

    /**
     * update user connection state on realod
     */
    const reloadUser = () => {

        if(localStorage.getItem("jwt")){
            //api check of the jwt token
            let formData = new FormData();
            formData.append("action", "check_jwt");
            const token = localStorage.getItem("jwt");

            fetch(process.env.REACT_APP_API_URL + '/index.php',
                {
                    method: 'POST',
                    body : formData,
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => response.json())
                .then(json => {
                    if (json.success === true)
                        login(token)
                    else
                        logout()
                });
        }else
            logout()

    }

    useEffect(reloadUser, []);

    return (
        <UserContext.Provider value={{ user, login, logout, reloadUser }}>
            {user.init && children}
        </UserContext.Provider>
    );
};
