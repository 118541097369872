import React from 'react';
import {HashLink as Link} from 'react-router-hash-link';

const Footer = () => {

  const retailer = sessionStorage.getItem("retailer")
  return (
    <footer>

      <div id={"footer_wrapper"}>

        <div id={"footer_menu"}>
          <nav>
            <Link to={"/faq"}>FAQ</Link>
            <Link to={"/#contactStrate1"} smooth>Contact</Link>
            <a href="/file/Règlement.pdf" target={"_blank"}>Règlement du jeu</a>
            <a href="/file/Politique%20de%20confidentialité.pdf" target={"_blank"}>Politique de Confidentialité</a>
            {retailer === "joueclub" && <a href="https://www.joueclub.fr/contenu/politique-de-donnees-personnelles.html" target={"_blank"}>Politique de Cookies</a>}
            {retailer !== "joueclub" && <a href="/file/Politique%20de%20cookies.pdf" target={"_blank"}>Politique de Cookies</a>}
            <Link to={"/informations-legales"}>Informations légales</Link>
          </nav>
        </div>

        <div id={"footer_mention"}>
          LEGO®, le logo LEGO® et la Minifigure sont des marques déposées du Groupe LEGO®. <br className={"d-lg-none"}/>
          ©2024 The LEGO® Group.
        </div>


        <div className={"footer_logo_wrapper"}>
          <div className={"footer_logo"}>
            {/*<a id={"footer_logo_partenaire"} href={"https://www.academiedugout.fr"} target={"_blank"}/>*/}
            <a id={"footer_logo_tlc"} href={"https://www.tlcmarketing.com/france"} target={"_blank"}/>
          </div>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
