import React, {useContext, useEffect} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import {UserContext} from "../context/UserContext";

const LogOffRoute = (props) => {

    const { user } = useContext(UserContext);

    if(user.connected)
        window.location.href="/mon-compte"
    else
        return(props.children)
}

export default LogOffRoute