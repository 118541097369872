import React, {useContext, useEffect, useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';

import {useIsInitialized} from '@s-group/react-usercentrics'

import Header from "./header"
import Footer from "./footer"

import ScrollTop from "./_scrollTop.jsx"
import ScrollToHashElement from "./_ScrollToHashElement";

import { Helmet } from 'react-helmet';
import seo_data from '../file/seo.json';
import {UserContext} from "./context/UserContext";
import Retailer from "./_retailer";

const Layout = () => {

    const [pageName, setPageName] = useState("")
    const {pathname, hash} = useLocation();

    const [seoState, setSeoState] = useState({
        "title" : "",
        "description" : "",
        "keywords" : ""
    })

    const {login, logout, user} = useContext(UserContext)

    const isInitialized = useIsInitialized()

    /**
     * Set page name from the windows path
     */
   useEffect(() => {

       //set page name for wrapper
       const currentPageName = pathname.replace('/','').split("/").join("_")
       setPageName(currentPageName === "" ? 'index':currentPageName)

       window.scrollTo(0, 0)

       //mixpanel handler
       window.mixpanelhandler = {
           track : function(event, param){

               //add retailer to tracking parameters
               const retailer = sessionStorage.getItem("retailer")
               if(retailer)
                   param = {...param,"Retailer" : retailer}


               //console.log(window.mixpanel,process.env.REACT_APP_MIXPANEL_TOKEN)

               if(window.mixpanel !== undefined && process.env.REACT_APP_MIXPANEL_TOKEN != ""){
                   //mixpanel actif
                   //console.log("mixpanel dispo");

                   if(!this.init){
                       window.mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {api_host: process.env.REACT_APP_MIXPANEL_PROXY});
                       window.mixpanel.register({
                           "Country" : "France",
                           "Job ID" : process.env.REACT_APP_MIXPANEL_JOBID
                       });

                       this.init = true
                   }

                   window.mixpanel.track(event,param);
               }
               /*else if(process.env.REACT_APP_MIXPANEL_DEBUG && process.env.REACT_APP_MIXPANEL_DEBUG === "TRUE"){
                   //mixpanel inactif
                   console.log("mixpanel pas dispo");
                   console.log("track",event,param);
               }*/

               if(process.env.REACT_APP_MIXPANEL_DEBUG && process.env.REACT_APP_MIXPANEL_DEBUG === "TRUE"){
                   console.log("track ->",event,param);
               }

               return true;
           },
           init : false
       }

       //seo stuff
       const seo = seo_data[currentPageName] !== undefined ? seo_data[currentPageName] : seo_data["default"]

       setSeoState({
           title : seo.title,
           description : seo.description,
           keywords : seo.keyword
       })

    },[pathname,hash])

    /**
     * handle mixpanel event
     */
   useEffect(() => {

        if(!isInitialized)
            return;

        switch(pageName){
            case "index" : window.mixpanelhandler.track("Participation_Home loaded",{"Result count" : 1}); break;
            case "jeu_bmx" : window.mixpanelhandler.track("BMX",{"Result count" : 1}); break;
            case "jeu_swim" : window.mixpanelhandler.track("Swim",{"Result count" : 1}); break;
            case "jeu_run" : window.mixpanelhandler.track("Run",{"Result count" : 1}); break;
        }

    }, [isInitialized, pageName])


    /*
    UPDATE USERCENTRICS STYLE
     */
    useEffect(() => {

        if(!document.getElementById("usercentrics-root"))
            return

        var style = document.createElement( 'style' )
        style.innerHTML = '#uc-center-container{ align-self: flex-start; border-radius: 0; }'
        document.getElementById("usercentrics-root").shadowRoot.appendChild( style )
    }, [document.getElementById("usercentrics-root")])

    return (
        <>
            <div className="page_wrapper" id={"page_" + pageName + "_wrapper"}>
                <Retailer/>
                <Helmet>
                    <title>{seoState.title}</title>
                    <meta name="description" content={seoState.description} />
                    <meta name="keyword" content={seoState.keywords} />
                </Helmet>

                <Header/>

                <div className="content_wrapper" id={"content_" + pageName + "_wrapper"}>
                    <Outlet/>
                </div>

                <Footer/>

                <ScrollTop/>
                <ScrollToHashElement/>
            </div>
        </>
    );
}

export default Layout;
