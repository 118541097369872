import React from 'react';
import PageTitle from "./_title";
import Contact from "./contact";

const MentionsLegales = () => {
  return (
    <>
      <PageTitle></PageTitle>
      <div className={"strate first bottom"} id={"mlStrate1"}>
        <div>
          <h1 className={"line yellow left"}>informations légales</h1>
          <div id={"ml_wrapper"}>

            <div className={"block"}>
              <h4>Le site est édité par :</h4>

              <p>
                TLC Marketing<br/>
                92 avenue de Wagram – 75017 Paris<br/>
                N° SIREN : 491 414 306<br/>
                N° SIRET : 491 414 306 00036<br/>
                RCS : Paris B 491 414 306<br/>
                SARL au capital social de : 150 000€<br/>
                Numéro de TVA intracommunautaire : <br className={"d-lg-none"}/>FR 70 491 414 306<br/>
                Adresse de courrier électronique : <a href="mailto:bonjour@tlcmarketing.com">bonjour@tlcmarketing.com</a>
              </p>

            </div>
            <div className={"block"}>
              <h4>Pour le compte de :</h4>

              <p>
                LEGO®<br/>
                75 rue de Tocqueville – 75017 Paris<br/>
                N° SIREN : 806 220 216<br/>
                N° SIRET : 806 220 216 00048<br/>
                RCS : Paris B 806 220 216<br/>
                S.A.S au capital social de 1 142 400€<br/>
                Numéro de TVA intracommunautaire :<br className={"d-lg-none"}/> FR 95 806 220 216
              </p>
            </div>
            <div className={"block"}>
              <h4>Ce site est hébergé par la société :</h4>

              <p>
                Cloud Media<br/>
                6 rue du Général Clergerie – 75116 PARIS<br/>
                N° SIREN : 752 470 971<br/>
                N° SIRET : 752 470 971 00025<br/>
                RCS : Paris B 752 470 971<br/>
                SAS au capital social de : 8 000€<br/>
                Numéro de TVA intracommunautaire :<br className={"d-lg-none"}/> FR 19 752 470 971<br/>
              </p>

            </div>

            <div className={"smallBlock"}>
              La conception, le design et le développement du site ont été effectués <br className={"d-none d-lg-block"}/>par les agences suivantes : TLC Marketing France et Cloud Media.
            </div>

          </div>
        </div>
      </div>
      <Contact/>
    </>

  );
};

export default MentionsLegales;


