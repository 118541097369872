import React from 'react';

import Contact from "./contact";
import PageTitle from "./_title";
import {Link} from "react-router-dom";
import {TeamBloc} from "./home";

const FinOpe = () => {

	return(
		<>
			<PageTitle/>

			<div id="strate1" className={"strate bottom first"}>

				<h1>L’opération <br className={"d-lg-none"}/>"embarquez pour Les Jeux LEGO®"<br/> est désormais terminée.</h1>
				<p>Merci à tous pour vos participations !</p>
				<p>Retrouve toute notre actualité sur <br className={"d-lg-none"}/><a href={"https://www.lego.com/fr-fr"} target={"_blank"} className={"red underline"}>www.lego.com/fr-fr</a> <br/>ou continue de t’entraîner aux jeux LEGO®.</p>
				<br/>
				<div className={"text-center"}>
					<Link to={"/#jeux"} className={"cta red"}>Je m'entraîne</Link>
				</div>
			</div>

			<Contact/>
		</>
	);
}
export default FinOpe;
