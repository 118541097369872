export const createObject = (ref, skin) => {

    //number between -30 & 30
    let y = Math.floor((Math.random() *  30) - 60);
    return {y : y,x: 0, ref : ref,skin : skin}
};

export const createObjectPX = (ref, skin, obstacle_height, game_wrapper_height) => {

    const amplitude = (obstacle_height - game_wrapper_height) / 2
    const y = Math.floor((Math.random() *  (amplitude)) - (amplitude/2));

    return {y : y,x: 0, ref : ref,skin : skin}
};