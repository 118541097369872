import React, {useEffect, useState} from 'react';
import {NavLink, Link} from "react-router-dom";

const HeaderMenu = (props) => {

  return(
      <>
      <div id="menu_overlay" onClick={props.handleHideMenu}/>
      <div id="menu">
        <nav>
          <div>
            <a href={"#0"} id={"menu_close"} onClick={props.handleHideMenu} />
            {
              React.Children.map(props.children, (child) => {
                return React.cloneElement(child, { onClick: props.handleHideMenu })
              })
            }
          </div>
        </nav>
      </div>
      </>
  )
}

const Header = () => {

  const [showMenu, setShowMenu] = useState(false)
  const handleShowMenu = () => setShowMenu(true)
  const handleHideMenu = () => setShowMenu(false)

  useEffect(()=> {

    if (showMenu) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

  },[showMenu])

  //retailer part
  const retailer = sessionStorage.getItem("retailer")
  let link = '/'
  let target = ''

  if(retailer === "kingjouet") {
    link = 'https://www.king-jouet.com/'
    target = '_blank'
  }

  return(
    <header id="header" isopen={showMenu ? "1" : "0"}>
      <div id="header_wrapper">

        <div className="header_body" >
          {/*<NavLink className="logo" to="#0"/>
          <NavLink className="main_logo" to={""}/>*/}
          <Link className={"logo_wrapper"} to={"/"}/>

          {retailer !== null && retailer !== "" &&
              <a id={"logo_retailer_wrapper"} href={link} target={target} className={retailer}/>
          }

          <Link className={"connection_cta"} to={"/mon-compte"}>Mon compte</Link>
          <div id="burger" onClick={() => setShowMenu(!showMenu) }>
            <div/>
            <div/>
            <div>Menu</div>
          </div>
        </div>

      </div>

      <HeaderMenu handleHideMenu={handleHideMenu}>
        <Link to="/">Accueil</Link>
        <Link to="/#jeux" smooth>Les Jeux LEGO®</Link>
        <Link to="/#comment-participer" smooth>Comment participer ?</Link>
        <Link to="/mon-compte">Mon compte</Link>
        <Link to="/#les-surprises">Les surprises LEGO®</Link>
        <Link to="/faq">FAQ</Link>
      </HeaderMenu>

    </header>
  )
}

export default Header;

