import React, {useCallback, useEffect, useRef, createRef, useImperativeHandle, useState, useContext} from "react";
import {useSetState} from "react-use";
import {gsap} from 'gsap';

import {Modal} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {getDotation} from "../../utils/utils";


const Player = () => {

    return(
        <div id={"player"} className={"idle"}>
            <div className={"idle"}/>
            <div className={"start"}/>
            <div className={"jump"}/>
            <div className={"fly"}/>
        </div>
    )
}

const GameUi = React.forwardRef((props, ref) => {

    const gaugeRef = createRef()
    const cursorRef = createRef()

    useImperativeHandle(ref, () => ({
        get gauge() {
            return gaugeRef.current;
        },
        get cursor() {
            return cursorRef.current;
        }
    }));

    return(
        <div id={"game_ui"}>
            <div className={"gauge stop"} ref={gaugeRef}>
                <div className={"cursor"} ref={cursorRef}/>
            </div>
            <div className={"score"}>{props.score} M</div>
        </div>
    )
})

const Background = React.forwardRef((props, ref) => {

    return (
        <div id={"bmx_background"} ref={ref}>
            <div className={"space"}>
                <div id={"star1_1"} className={"star1"}/>
                <div id={"star1_2"} className={"star1"}/>
                <div id={"star1_3"} className={"star1"}/>
                <div id={"star2_1"} className={"star2"}/>
                <div id={"star2_2"} className={"star2"}/>
                <div id={"star3_1"} className={"star3"}/>
                <div id={"star3_2"} className={"star3"}/>
                <div id={"star3_3"} className={"star3"}/>

                <div className={"galaxy"}/>

                <div className={"satellite1"}/>
                <div className={"satellite2"}/>
            </div>
            <div className={"sky"}>
                <div id={"pigeon1"} className={"pigeon"}/>
                <div id={"pigeon2"} className={"pigeon"}/>

                <div id={"bird1"} className={"bird"}/>
                <div id={"bird2"} className={"bird"}/>

                <div className={"plane_red"}/>
                <div className={"plane"}/>

                <div className={"cloud1"}/>
                <div className={"cloud2"}/>
                <div className={"cloud3"}/>
                <div className={"cloud4"}/>
            </div>
            <div className={"jump"}/>
        </div>
    )

})

const Bmx = () => {

    //handle pa id if exist
    const [paId, setPaId] = useState(null)
    const [dotation, setDotation] = useState(null)
    const location = useLocation()

    useEffect(() => {
        if(location?.state?.pa_id) {
            setPaId(location.state.pa_id)
        }
    }, []);

    //state
    const initialState = {
        score : 0
    }
    const [gameState, setGameState] = useSetState(initialState)

    //keypress state
    const keyPress = useRef(false)

    //Modal state
    const [showStartModal, setShowStartModal] = useState(true);
    const [showEndModal, setShowEndModal] = useState(false);

    //ref
    const gameRef = useRef();
    const backgroundRef = useRef();
    const gaugeRef = useRef();

    const toggleFullScreen = (state) => {

        if ("ontouchstart" in document.documentElement){
            let elem = document.getElementById("fullscreenHandler")

            if(state){
                if (document.exitFullscreen) {
                    elem.requestFullscreen()
                }

            }else{

                if (document.exitFullscreen) {
                    document.exitFullscreen().catch((err) => console.error(err));
                }

            }
        }

    }

    //start game
    const startGame = () => {

        window.mixpanelhandler.track("BMX",{"Let Go" : 1})

        setGameState(initialState)
        gameRef.current.addEventListener("click", getGaugeValue)

        //start gauge
        gaugeRef.current.gauge.classList.remove("stop")

        //setfullScreen
        toggleFullScreen(true)
        setShowStartModal(false)
    }

    //reset game
    const resetGame = () => {

        window.mixpanelhandler.track("BMX",{"Game Replay" : 1})

        keyPress.current = false
        gaugeRef.current.gauge.classList.add("stop")
        gsap.to("*", {clearProps:true})
        setShowEndModal(false);
        setShowStartModal(true);
    }

    /**
     * Trigger animation after user clic
     */
    const startAnimation = (gaugeValue) => {

        //config
        //const scale = 1.5
        const scale = 1.5 + (1 * gaugeValue)
        const max_duration = 25

        //calculate data
        const distance = 80 * gaugeValue
        const time_flying =  ((max_duration * gaugeValue) / scale)
        const score = Math.round((max_duration * 1000 ) * gaugeValue)
        let score_fragment = (score / (time_flying - 1)) / 10

        if(score_fragment < 0)
            score_fragment = score_fragment * -1

        //create gsap timeline
        gsap.timeline({
            /*repeat : -1*/
        })
        .to(".gauge",{
            duration : 0.5,
            opacity : 0
        })
        .to("#player",{
            duration : 1,
            left : "250px",
            ease : "linear",
            onStart : () => {
                document.getElementById("player").classList.add("start")
                document.getElementById("player").classList.remove("idle")
            }, // start moving
        })
        .add("ramp1") //go on the ramp
        .to("#bmx_background",{
            duration : 0.5,
            left : "-180px",
            bottom : "-100px",
            ease : "linear"
        },"ramp1")
        .to("#player",{
            duration : 0.5,
            transform : 'rotate(-30deg)',
            ease : "linear",
            onStart : () => {
                document.getElementById("player").classList.remove("start")
                document.getElementById("player").classList.add("jump") //start jump
            }
        },"ramp1")
        .add("ramp2") //go on the ramp part 2
        .to("#bmx_background",{
            duration : 0.5,
            left : "-360px",
            bottom : "-170px",
            ease : "linear"
        },"ramp2")
        .to("#player",{
            duration : 0.5,
            transform : 'rotate(-42deg)',
            bottom : '115px',
            ease : "linear",
        },"ramp2")
        .add("jump")
        .to("#bmx_background",{
            duration : max_duration * gaugeValue,
            transform : `translate3d(-${distance}%,${distance}%,0)`,
            /*left : "-2500px",
            bottom : "-2500px",*/
            ease : "linear",
            onStart : () => {
                document.getElementById("player").classList.add("fly")
                document.getElementById("player").classList.remove("jump")

                //start score timer
                const timeout = setInterval(() => {
                    setGameState((oldState) => {
                        let new_score = oldState.score+score_fragment

                        if(oldState.score >= score) {
                            new_score = score
                            clearTimeout(timeout)
                        }

                        return {score : Math.round(new_score)}
                    })
                },100)

            } //stop jump
        },"jump")
        .to("#player",{
                duration : 5 * gaugeValue,
                transform : `transform: translate3d(-50%,50%,0);`,
                left : "40%",
                bottom : "50%",
                /*ease : "linear",*/
            },"jump")
        .add("fall")
        .to("#player",{
            duration : 2,
            /*'transform-origin' : "center center",*/
            transform : 'rotate(40deg)',
            bottom : "-400px",
            left : "70%",
            ease : "linear",
            onComplete : () => {
                document.getElementById("player").classList.add("idle")
                document.getElementById("player").classList.remove("fly")
                handleGameEnding()
            }
        },"fall")
        .timeScale(scale)
    }

    /**
     * Get gauge value and calculate everithings
     */
    const getGaugeValue = () => {

        //disable click
        gameRef.current.removeEventListener("click", getGaugeValue)

        const gauge = gaugeRef.current.gauge
        const cursor = gaugeRef.current.cursor

        //add class to stop cursor
        gauge.classList.add("stop")

        //calculate gauge percentage
        const p = parseFloat(getComputedStyle(cursor).bottom) / gauge.offsetHeight
        startAnimation(p)
    }

    const handleGameEnding = () => {
        toggleFullScreen(false)

        //play from account
        if(paId !== null)
            getDotation(paId,"bmx")
                .then(response => response.json())
                .then(json => {
                    if (json.success === true) {
                        //save dotation
                        setDotation(json.data.dotation)
                        //display end modal with link to dotation
                        setShowEndModal(true);
                        //track mixpanel
                        window.mixpanelhandler.track("BMX",{"Game Complete" : 1})
                    }else{

                    }
                });
        else
            setShowEndModal(true)
    }

    //set up key event
    const handlePlayerKeyDown = (e) => {
        if(e.which === 32 && !keyPress.current) {
            keyPress.current = true
            e.preventDefault()
            getGaugeValue()
        }
    }

    useEffect(() => {
        document.addEventListener('keydown',handlePlayerKeyDown);
        return () => document.removeEventListener('keydown',handlePlayerKeyDown);
    },[])

    return(
        <div id={"fullscreenHandler"}>

            <Modal show={showStartModal} onHide={() => setShowStartModal(false)} id={"modalStartBmx"} className={"game_modal start"} centered={true} backdrop={"static"}>
                <Modal.Body>
                    <Link id={"modal_close"} to={paId ? "/mon-compte":"/"}/>
                    <h2 className={"line yellow"}>Réalise le saut le plus haut<br/> avec ton vélo !</h2>
                    <p className={"mobile_instruction"}>Tape sur l’écran de ton smartphone pour courir le plus vite possible.</p>
                    <p className={"desktop_instruction"}>Utilise la barre d’espace de ton clavier ou clique avec ta souris au bon moment et lorsque la jauge est au maximum pour sauter le plus haut possible.</p>
                    <div className={"translation"}>BMX Freestyle = BMX Artistique.</div>
                    <button className={"cta yellow"} onClick={startGame}>C’est parti !</button>
                </Modal.Body>
            </Modal>

            <Modal show={showEndModal} onHide={() => setShowEndModal(false)} id={"modalEndBmx"} className={"game_modal end"} centered={true} backdrop={"static"} >
                <Modal.Body>
                    <h2>Tu as parcouru</h2>
                    <div className={"score"}><span>{gameState.score}</span>M</div>

                    {dotation === null &&
                        <>
                            <div className={"modal_block"} id={"modal_block_restart"}>
                                <p>Tu penses pouvoir faire mieux ?</p>
                                <button className={"cta red"} onClick={() => {
                                    window.mixpanelhandler.track("BMX",{"I train" : 1})
                                    resetGame()
                                }} >Je continue de m’entraîner</button>
                            </div>
                            <div className={"modal_block"} id={"modal_block_login"}>
                                <p>Tu te sens prêt à tenter ta chance ?</p>
                                <Link to={"/connexion"} className={"cta red"}>Je m’inscris/je me connecte</Link>
                            </div>
                        </>
                    }

                    {dotation !== null &&
                        <div className={"modal_block"} id={"modal_block_restart"}>
                            <Link to={"/dotation"} state={{dotation : dotation}} className={"cta red"}>Je découvre mon gain</Link>
                        </div>
                    }

                </Modal.Body>
            </Modal>

            <div className={"mobile_landscape_switcher"}/>

            <div id={"game_bmx_wrapper"} className={"game_wrapper"} ref={gameRef}>
                <GameUi ref={gaugeRef} score={gameState.score}/>
                <Background ref={backgroundRef}/>

                <Player/>
            </div>
        </div>
    )
}

export default Bmx