import {useMemo, useEffect, useState} from "react";
import { useLocation } from "react-router-dom";

const ScrollToHashElement = () => {

    let location = useLocation();
    const [hashElement, setHashElement] = useState("");

    useMemo(() => {
        let hash = location.hash;

        const removeHashCharacter = (str) => {
            const result = str.slice(1);
            return result;
        };

        if (hash) {
            setTimeout( () => setHashElement(document.getElementById(removeHashCharacter(hash))) , 50)
        }
    }, [location]);

    useEffect(() => {
        if (hashElement) {
            hashElement.scrollIntoView({
                behavior: "smooth",
                /*block: "nearest",*/
                inline: "nearest",
            });

            location.hash = null
            setHashElement(null)
        }
    }, [hashElement]);

    return null;
};

export default ScrollToHashElement;