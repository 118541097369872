export const getDotation = (pa_id, token) => {

    const jwt = localStorage.getItem("jwt")

    let formData = new FormData();
    formData.append('action', "burn_token");
    formData.append('pa_id', pa_id);
    formData.append("token", token);

    return fetch(process.env.REACT_APP_API_URL + '/index.php',
        {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
}