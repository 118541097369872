import React from "react";
import {useRouteError} from "react-router-dom";
import Header from "../header";
import Footer from "../footer";

const ErrorPage = () => {
    const error = useRouteError();

    return (

        <div className="page_wrapper" id={"page_404_wrapper"}>
            <Header/>
            <div className="content_wrapper" id={"content_404_wrapper"}>
                <div id="404_error_wrapper">
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error.statusText || error.message}</i>
                    </p>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default ErrorPage