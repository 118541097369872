import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './sass/reset.scss'
import './sass/style_mob.scss'
import './sass/style.scss'
import {UsercentricsProvider} from "@s-group/react-usercentrics";
//import './sass/style_max.scss'

const root = ReactDOM.createRoot(
    document.getElementById('root')
);

root.render(
    <>
        <UsercentricsProvider windowEventName="ucEvent">
            <App />
        </UsercentricsProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
