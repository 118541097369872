import React, {useRef, useState} from 'react';

const Contact = () => {

    return (
      <div id="contactStrate1" className={"strate bottom"}>
        <h3>TU AS des questions ou besoin<br/> de plus d’informations ?</h3>
        <p>Le Service Consommateurs <br className={"d-lg-none"}/> de TLC Marketing reste à ta disposition :</p>

        <div id={"contact_item_wrapper"}>

          <div className={"contact_item"} id={"contact_item_phone"}>
            <div className={"content"}>
              <div className={"title"}>Par téléphone</div>
              <div className={"contact_link"}><a href={"tel:0175850217"}>01 75 85 02 17</a></div>
              <div className={"detail"}>
                (Appel non surtaxé, prix d’un appel local, <br/>
                tarif en vigueur selon opérateur. Ligne active du lundi au vendredi, <br className={"d-lg-none"}/>
                de <br className={"d-lg-block d-none"}/>9h30 à 17h30, hors jours fériés)
              </div>
            </div>
          </div>

          <div className={"contact_item"} id={"contact_item_mail"}>
            <div className={"content"}>
              <div className={"title"}>Par e-mail</div>
              <div className={"contact_link"}>
                <a href={"mailto:lesjeuxlego@tlcrewards.com?subject=Op%C3%A9ration%20%22Embarquez%20pour%20les%20Jeux%20LEGO%C2%AE%22%20%2F%20Tes%20nom%20et%20pr%C3%A9nom"}>lesjeuxlego@tlcrewards.com </a>
              </div>
              <div className={"detail"}>
                (Objet : Opération "Embarquez pour les Jeux LEGO®" / Tes nom et prénom)
              </div>
            </div>
          </div>

        </div>
      </div>
  );
};

export default Contact;
