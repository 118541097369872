import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useRef, useState} from "react";
import * as Helper from "../../form/form_inscription_helper";
import * as Pattern from "../../form/form_pattern_helper";
import CustomFieldText from "../../form/CustomFieldText";
import PageTitle from "../_title";
import CustomUploadField from "../../form/CustomUploadField";
import {UserContext} from "../../context/UserContext";
import Contact from "../contact";
import {Carousel} from "react-bootstrap";
import {Link} from "react-router-dom";
import GenericModal from "../../_generic_modal";

const FileUpload = ({user, handleAddPa}) => {

    //generic modal state
    const [showModal, setShowModal] = useState(false)
    const [modalContent, setModalContent] = useState({
        "title" : "Information",
        "content" : ""
    })

    //handle file form
    const {register ,handleSubmit, formState: { errors },setValue,clearErrors,setError, reset } = useForm()
    const [file, setFile] = useState("")
    function setFilename(event,fieldname,setFileHandle) {

        if (event.target.files[0] !== undefined && Helper.checkIsUploadable(event.target.files[0])) {
            setValue(fieldname, event.target.files[0].name);
            setFileHandle(event.target.files[0]);
            clearErrors(fieldname);
        } else if (!Helper.checkIsUploadable(event.target.files[0])) {
            setError(fieldname, "filesize", Helper.InvalidErrorMessages.filesize);
        }
    }

    const handleFileSubmit = (data) => {
        let formData = new FormData();
        formData.append('form', JSON.stringify(data));
        formData.append("action", "upload");
        formData.append("ticket", file);

        fetch(process.env.REACT_APP_API_URL + '/index.php',
            {
                method: 'POST',
                body : formData,
                headers: {
                    'Authorization': `Bearer ${user.jwt}`
                }
            })
            .then(response => response.json())
            .then(json => {

                if(json.success) {

                    window.mixpanelhandler.track("Participation_Form_submitted",{"Result count" : 1})

                    handleAddPa(json.data.id, json.data.file)
                    reset()
                    setModalContent({
                        title : "Information",
                        content : "Votre preuve d'achat a bien été enregistrée !"
                    })
                    setShowModal(true)
                }
            });

    }

    return (
        <div className={"strate"} id={"accountStrate3"}>
            <p>
                <b>Mes preuves d’achat</b><br/>
                Pour chaque preuve d’achat enregistrée tu reçois 3 jetons pour tenter de gagner l’une des
                surprises LEGO®.
            </p>
            <form onSubmit={handleSubmit(handleFileSubmit)} id={"form_file"}>
                <CustomUploadField
                    label={"Preuve d’achat <br class='d-none d-lg-block'/><div>(ticket de caisse ou facture)</div><span>*</span>"}
                    warning={"*Le fichier ne doit pas dépasser les 7MO et doit être au format <br class='d-lg-none'/>JPEG, JPG, PNG ou PDF."}
                    name={"ticket"}
                    onchangeHandler={(e) => setFilename(e, "ticket", setFile)}
                    register={register("ticket", {required: Helper.EmptyErrorMessages.ticket})}
                    error={errors.ticket && errors.ticket && errors.ticket.message}
                />
                <div className={"cta_wrapper"}>
                    <button className={"cta red"}>Valider</button>
                </div>
            </form>
            <GenericModal title={modalContent.title} content={modalContent.content} handleClose={() => setShowModal(false)} show={showModal}/>
        </div>
    )

}

const FileListing = ({user,preuvesAchat, setPreuvesAchats}) => {

    useEffect(() => {

        let formData = new FormData();
        formData.append("action", "get_pa");

        fetch(process.env.REACT_APP_API_URL + '/index.php',
            {
                method: 'POST',
                body : formData,
                headers: {
                    'Authorization': `Bearer ${user.jwt}`
                }
            })
            .then(response => response.json())
            .then(json => {
                if(json.success)
                    setPreuvesAchats(json.data)
            });

    }, []);

    const createCarouselGame = (jetons, id) => {

        const content = [
            [
                "BMX Freestyle*",
                "Réalise le saut <br/>le plus haut <br/>avec ton vélo !",
                "/jeu/bmx",
                jetons.bmx
            ],
            [
                "Crazy Swim*",
                "Nage en évitant <br/>les obstacles dans <br/>le bassin !",
                "/jeu/crazy-swim",
                jetons.swim
            ],
            [
                "Fast Runner*",
                "Cours le plus vite possible <br/>et arrive le premier en <br/>faisant le meilleur temps !",
                "/jeu/fast-runner",
                jetons.run
            ]
        ]

        let items = [];
        for(let i=0; i<3; i++){

            items.push(
                <Carousel.Item key={"gameItem_" + i}>
                    <div className={`home_game_item home_game_item_${i+1} ${content[i][3] === 1 ? '':'disabled'}`}>
                        <div>
                            <div className={"picture"}/>
                            <div className={"title"}>
                                {content[i][0]}
                            </div>
                            <div className={"content"} dangerouslySetInnerHTML={{__html : content[i][1]}}/>
                            {content[i][3] === 1 && <Link className={"cta"} to={content[i][2]} state={{pa_id: id}}>Je tente ma chance</Link>}
                            {content[i][3] !== 1 && <Link className={"cta"} to={'/'}>Je tente ma chance</Link>}
                        </div>
                    </div>

                </Carousel.Item>
            )

        }

        let items_desktop = [];

        for(let i=0; i<3; i++){

            items_desktop.push(
                <div className={`home_game_item home_game_item_${i+1} ${content[i][3] === 1 ? '':'disabled'}`}>
                    <div>
                        <div className={"picture"}/>
                        <div className={"title"}>{content[i][0]}</div>
                        <div className={"content"} dangerouslySetInnerHTML={{__html: content[i][1]}}></div>
                        {content[i][3] === 1 && <Link className={"cta"} to={content[i][2]} state={{pa_id: id}}>Je tente ma chance</Link>}
                        {content[i][3] !== 1 && <Link className={"cta"} to={'/'}>Je tente ma chance</Link>}
                    </div>
                </div>
            )

        }

        return (
            <>
                <div className={"d-lg-none game_carousel_wrapper"}>
                    <Carousel controls={true} interval={null} indicators={null}>
                        {items}
                    </Carousel>
                </div>
                <div className={"home_game_wrapper d-lg-flex d-none"}>
                    {items_desktop}
                </div>
                <div className={"translation"}>
                    *Fast Runner = Coureur Rapide / Crazy Swim = Nage en Folie / BMX Freestyle = BMX Artistique.
                </div>
            </>
        );
    }

    function toggleGameElement(event) {

        let gameBlock = event.target.parentNode;
        let gameBlockState = gameBlock.classList.contains("open");

        let allgameBlock = document.getElementsByClassName("pa_item");

        [].forEach.call(allgameBlock, function (b) {
            b.classList.remove("open");
        });

        if (gameBlockState === true)
            gameBlock.classList.remove("open");
        else
            gameBlock.classList.add("open");
    }

    return (
        <div className={"strate bottom"} id={"accountStrate4"}>
            <div id={"pa_wrapper"}>
                {preuvesAchat.map((pa) => {
                    return(<div className={"pa_item"} id={`pa_item${pa.key}`}>
                        <div className={"title"} onClick={(e) => toggleGameElement(e)}>Preuve d'achat n°{pa.key} /<span> {pa.nicename}</span></div>
                        <div className={"content"}>
                            {createCarouselGame(pa.jeton, pa.id)}
                        </div>
                    </div>)
                })}

            </div>
        </div>
    )
}

const MonCompte = () => {

    //Context user
    const {user, logout} = useContext(UserContext)

    //Handle password form
    const {register, handleSubmit, formState: {errors},reset} = useForm()
    const submitRef = useRef(null)
    const [showPasswordForm, setShowPasswordForm] = useState(false)
    const [passwordFormMessage, setPasswordFormMessage] = useState({
        success : "",
        error : ""
    })

    const handleShowPasswordForm = (show) => {
        setShowPasswordForm(show)

        if(!show)
            reset()

        setPasswordFormMessage({
            success : "",
            error : ""
        })
    }

    const handleFormSubmit = (data) => {
        let formData = new FormData();
        formData.append('form',JSON.stringify(data))
        formData.append("action", "change_password")

        fetch(process.env.REACT_APP_API_URL + '/index.php',
            {
                method: 'POST',
                body : formData,
                headers: {
                    'Authorization': `Bearer ${user.jwt}`
                }
            })
            .then(response => response.json())
            .then(json => {
                if(json.success) {
                    handleShowPasswordForm(false)
                    setPasswordFormMessage({success : "Mot de passe mis à jour avec succès"})
                }else{
                    setPasswordFormMessage({error : json.message})
                }
            });
    }

    //file listing
    const [preuvesAchat, setPreuvesAchats] = useState([])
    const handleAddPa = (id, nicename) => {
        setPreuvesAchats([...preuvesAchat,
            {
                id: id,
                key : preuvesAchat.length+1,
                nicename: nicename,
                jeton: {
                    bmx :1,
                    swim:1,
                    run:1
                }
            }
        ])
    }

    //load user data
    const [userData, setUserData] = useState([])
    useEffect(() => {
        let formData = new FormData();
        formData.append("action", "get_user");

        fetch(process.env.REACT_APP_API_URL + '/index.php',
            {
                method: 'POST',
                body : formData,
                headers: {
                    'Authorization': `Bearer ${user.jwt}`
                }
            })
            .then(response => response.json())
            .then(json => {
                if(json.success)
                    setUserData(json.data)
            });
    }, []);

    return (
        <div className={"account_wrapper"}>
            <PageTitle/>
            <div className={"strate first"} id={"accountStrate1"}>
                <button id={"logout"} onClick={logout}>Se déconnecter</button>
                <h1 className={"line yellow left"}>Mon compte</h1>
                <div className={"user_info"}>
                    <h4>Mes informations</h4>
                    <div>
                        <label>Civilité</label>
                        <span>{userData?.civilite}</span>
                    </div>
                    <div>
                        <label>Nom</label>
                        <span>{userData?.nom}</span>
                    </div>
                    <div>
                        <label>Prénom</label>
                        <span>{userData?.prenom}</span>
                    </div>
                    <div>
                        <label>Adresse e-mail</label>
                        <span>{userData?.mail}</span>
                    </div>

                    <div id={"message_wrapper"}>
                        {passwordFormMessage.success !== "" && passwordFormMessage.success}
                    </div>

                    {!showPasswordForm &&
                        <div>
                            <button className={"cta grey"} onClick={() => handleShowPasswordForm(true)}>Modifier mon mot de passe</button>
                        </div>
                    }
                    {showPasswordForm &&
                        <div>
                            <form onSubmit={handleSubmit(handleFormSubmit)} id={"form_password"}>
                                <CustomFieldText name="password" label="Nouveau mot de passe*" type={"password"}
                                                 register={register("password", {required: Helper.EmptyErrorMessages.password})}
                                                 error={errors.password && errors.password.message}
                                />
                                <CustomFieldText name="password_confirm" label="Confirmation Nouveau mot de passe*" type={"password"}
                                                 register={register("password_confirm", {required: Helper.EmptyErrorMessages.password})}
                                                 error={errors.password_confirm && errors.password_confirm.message}
                                />
                            </form>
                            <div id={"error_wrapper"}>
                                {passwordFormMessage.error !== "" && passwordFormMessage.error}
                            </div>
                            <div className={"cta_wrapper"}>
                                <button className={"cta grey"} form={"form_password"}>Valider</button>
                                <button className={"cta grey"} onClick={() => handleShowPasswordForm(false)}>Annuler</button>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {userData?.dotation &&
                <div className={"strate"} id={"accountStrate2"}>
                    <div className={`dotation ${userData?.dotation.toLowerCase()}`}/>
                </div>
            }


            <FileUpload user={user} handleAddPa={handleAddPa}/>
            <FileListing user={user} preuvesAchat={preuvesAchat} setPreuvesAchats={setPreuvesAchats}/>

            <Contact/>
        </div>
    )
}

export default MonCompte