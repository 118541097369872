import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Carousel, Modal} from "react-bootstrap";
import Contact from "./contact";

const Home = () => {

    //get current retailer
    const retailer = sessionStorage.getItem("retailer")

    const [showStartModal, setShowStartModal] = useState(false)

    /*useEffect(() => {
        const dismissPopup = localStorage.getItem("candia_popup") ?? false

        if(!dismissPopup)
            setShowStartModal(true)
    }, [])*/

    const handleCloseModal = () => {
        localStorage.setItem("candia_popup",true)
        setShowStartModal(false)
    }

    const createCarouselHowto = () => {

        //get current retailer
        const retailer = sessionStorage.getItem("retailer")

        const content = [
            [
                retailer === "joueclub"?"ACHÈTE CHEZ JOUÉCLUB":"ACHÈTE",
                "<b>au minimum 20€</b> de produits LEGO®<br class='d-lg-none'/> <b>entre le 20 juillet <br class='d-none d-lg-block'/>et le 10 août 2024 inclus</b>. Conserve ta preuve d’achat,<br/> elle te sera demandée pour enregistrer<br class='d-lg-none'/> ta participation !"
            ],
            [
                "ENTRAÎNE-TOI",
                "à l’un des 3 jeux LEGO® disponibles <a href='/#jeux' class='underline'>ici</a> <br class='d-lg-none'/>puis <br class='d-none d-lg-block'/><b>crée ton compte</b> en renseignant tes <br class='d-lg-none'/>coordonnées <br class='d-none d-lg-block'/>et en téléchargeant ta preuve <br class='d-lg-none'/>d’achat ou <b>connecte-toi <br class='d-none d-lg-block'/>à ton compte</b> <br class='d-lg-none'/>s’il est déjà créé, pour <b>tenter ta chance</b>."
            ],
            [
                "DÉCOUVRE",
                "instantanément si tu as gagné ou perdu :<br/>en cas de gain, et si ta participation est <br class='d-lg-none'/>valide, tu recevras un e-mail <br class='d-none d-lg-block'/>sous 5 jours <br class='d-lg-none'/>ouvrés avec les instructions pour bénéficier <br class='d-lg-none'/>de ta dotation. <br class='d-none d-lg-block'/>En cas de perte, tu pourras <br class='d-lg-none'/>à nouveau tenter ta chance en jouant <br/>aux deux autres jeux LEGO® via ton compte accessible <a href='/mon-compte' class='underline'>ici</a>. "
            ],
        ]

        let items = [];
        for(let i=0; i<3; i++){

            items.push(
                <Carousel.Item key={"HowToItem" + i}>
                    <div className={`carousel_howto_item`} id={"carousel_howto_item" + (i + 1)}>
                        <div className={"picture"}/>
                        <div className={"title"}>{i + 1}</div>
                        <div className={"content"}>
                            {content[i].map((item) => {
                                return (<p dangerouslySetInnerHTML={{__html: item}}/>)
                            })}
                        </div>
                    </div>
                </Carousel.Item>
            )

        }

        return (
            <div id={"howto_carousel_wrapper"}>
                <Carousel controls={true} interval={null} indicators={true} className={"control_bottom"}>
                    {items}
                </Carousel>
            </div>
        );
    }

    const createCarouselGame = () => {

        const content = [
            [
                "BMX Freestyle*",
                "Réalise le saut <br/>le plus haut <br/>avec ton vélo !",
                "/jeu/bmx"
            ],
            [
                "Crazy Swim*",
                "Nage en évitant <br/>les obstacles dans <br/>le bassin !",
                "/jeu/crazy-swim"
            ],
            [
                "Fast Runner*",
                "Cours le plus vite possible <br/>et arrive le premier en <br/>faisant le meilleur temps !",
                "/jeu/fast-runner"
            ]
        ]

        let items = [];
        for(let i=0; i<3; i++){

            items.push(
                <Carousel.Item key={"gameItem_" + i}>
                    <div className={`home_game_item`} id={"home_game_item_" + (i + 1)}>
                        <div>
                            <div className={"picture"}/>
                            <div className={"title"}>
                                {content[i][0]}
                            </div>
                            <div className={"content"} dangerouslySetInnerHTML={{__html : content[i][1]}}/>
                        </div>
                        <Link className={"cta yellow"} to={content[i][2]}>Je m'entraîne</Link>
                    </div>

                </Carousel.Item>
            )

        }

        let items_desktop = [];

        for(let i=0; i<3; i++){

            items_desktop.push(
                <div className={`home_game_item`} id={"home_game_item_" + (i + 1)}>
                    <div>
                        <div className={"picture"}/>
                        <div className={"title"}>{content[i][0]}</div>
                        <div className={"content"} dangerouslySetInnerHTML={{__html: content[i][1]}}></div>
                    </div>
                    <Link className={"cta yellow"} to={content[i][2]}>Je m'entraîne</Link>
                </div>
            )

        }

        return (
            <>
                <div id={"game_carousel_wrapper"} className={"d-lg-none"}>
                    <Carousel controls={true} interval={5000} indicators={null}>
                        {items}
                    </Carousel>
                </div>
                <div id={"home_game_wrapper"} className={"d-lg-flex d-none"}>
                    {items_desktop}
                </div>
            </>
        );
    }

    const createCarouselDotation = () => {

        //get current retailer
        const retailer = sessionStorage.getItem("retailer")

        let dotation_url = {
            "photo" : 'https://www.lego.com/fr-fr/product/retro-camera-31147',
            "flower" : 'https://www.lego.com/fr-fr/product/bouquet-of-roses-10328',
            "concorde" : 'https://www.lego.com/fr-fr/product/concorde-10318'
        }

        switch(retailer){
            case "fnac" :

                dotation_url = {
                    "photo" : "https://www.fnac.com/LEGO-Creator-31147-L-appareil-photo-retro/a18821728/w-4",
                    "flower" : "https://www.fnac.com/LEGO-Icons-10328-Le-bouquet-de-roses/a18821697/w-4",
                    "concorde" : "https://www.fnac.com/LEGO-Icons-10318-Le-Concorde/a18821609/w-4"
                }

                break;

            case "joueclub" :
                dotation_url = {
                    "photo" : "https://www.joueclub.fr/jeux-de-constructions-maquettes/lego-creator-31147-l-appareil-photo-retro-5702017584966.html",
                    "flower" : "https://www.joueclub.fr/jeux-de-constructions-maquettes/lego-icons-10328-le-bouquet-de-fleurs-rose-5702017583488.html",
                    "concorde" : "https://www.joueclub.fr/jeux-de-constructions-maquettes/lego-icons-10318-le-concorde-5702017416915.html"
                }

                break;

            case "kingjouet" :
                dotation_url = {
                    "photo" : "https://www.king-jouet.com/jeu-jouet/jeux-constructions/lego-planchettes/ref-951560-31147-lego-creator-l-appareil-photo-retro.htm",
                    "flower" : "https://www.king-jouet.com/jeu-jouet/jeux-constructions/lego-planchettes/ref-951556-10328-lego-icons-le-bouquet-de-roses.htm",
                    "concorde" : "https://www.king-jouet.com/jeu-jouet/jeux-constructions/lego-planchettes/ref-951598-10318-lego-icons-le-concorde.htm"
                }

                break;

            case "lagranderecre" :
                dotation_url = {
                    "photo" : "https://www.lagranderecre.fr/jeux-de-construction/jeux-de-construction-en-briques/coffrets-de-briques-et-construction-classique/l-appareil-photo-retro-lego-creator-31147.html",
                    "flower" : "https://www.lagranderecre.fr/jeux-de-construction/jeux-de-construction-en-briques/coffrets-de-briques-et-construction-classique/bouquet-de-roses-lego-icons-10328.html",
                    "concorde" : "https://www.lagranderecre.fr/jeux-de-construction/jeux-de-construction-en-briques/ville-et-univers-urbains/le-concorde-lego-icons-10318.html"
                }

                break;

        }

        const content = [
            [
                '31147',
                'L’Appareil Photo Rétro',
                'Construis un appareil photo <br/>rétro avec objectif mobile,<br/> pellicule et lanière.',
                dotation_url.photo
            ],
            [
                '10328',
                'Le Bouquet de Roses',
                'Une élégante décoration pour la maison ou le bureau.',
                dotation_url.flower
            ],
            [
                '10318',
                'Le Concorde',
                'La réplique détaillée de <br/>l’avion de ligne supersonique <br/>le plus célèbre au monde !',
                dotation_url.concorde
            ]
        ]

        let items = [];
        for(let i=0; i<3; i++){

            items.push(
                <Carousel.Item key={"DotationItem" + i}>
                    <div className={`dotation_item`} id={"dotation_item" + (i + 1)}>
                        <a href={content[i][3]} target={"_blank"}></a>
                        <div className={"ref"}>{content[i][0]}</div>
                        <div className={"name"}>{content[i][1]}</div>
                        <div className={"description"} dangerouslySetInnerHTML={{__html: content[i][2]}}/>
                    </div>
                </Carousel.Item>
            )

        }

        let items_desktop = [];

        for(let i=0; i<3; i++){

            items_desktop.push(
                <div className={`dotation_item`} id={"dotation_item" + (i + 1)}>
                    <a href={content[i][3]} target={"_blank"}></a>
                    <div className={"ref"}>{content[i][0]}</div>
                    <div className={"name"}>{content[i][1]}</div>
                    <div className={"description"} dangerouslySetInnerHTML={{__html: content[i][2]}}/>
                </div>
            )

        }

        return (
            <>
                <div id={"dotation_carousel_wrapper"} className={"d-lg-none"}>
                    <Carousel controls={true} interval={5000} indicators={true} className={"control_bottom"}>
                        {items}
                    </Carousel>
                </div>
                <div id={"home_dotation_wrapper"} className={"d-lg-flex d-none"}>
                    {items_desktop}
                </div>
            </>
        );
    }

    return (
        <>
            <Modal show={showStartModal} onHide={handleCloseModal} id={"modalHome"} centered={true} backdrop={"static"}>
                <Modal.Body>
                    <Link id={"cta_start_jeu"} to={"/jeu"} onClick={handleCloseModal}>Découvrir le jeu</Link>
                    <Link id={"cta_start_decouvrir"} to={"/"} onClick={handleCloseModal}>Découvrir l'offre</Link>
                </Modal.Body>
            </Modal>

            <div id={"homeStrate1"} className={"strate first"} retailer={retailer}>

                <div>DU 20 juiLLET AU 10 AOûT 2024</div>

                {retailer === "joueclub" && <Link id={"cta_start_retailer"} className={"cta red"} to={"https://www.joueclub.fr/contenu/lego-.html"} target={"_blank"}>J’achète</Link>}
                {retailer === "kingjouet" && <Link id={"cta_start_retailer"} className={"cta red"} to={"https://www.king-jouet.com/jeux-jouets/selection-lego-duplo/page1.htm"} target={"_blank"}>J’achète</Link>}

                <Link className={"cta red"} to={"/connexion"} onClick={() => window.mixpanelhandler.track("Sign in Selected",{"Result count" : 1})}>Je m’inscris/Je me connecte</Link>
                <p>*Offre cumulable, soumise à conditions et valable dès 20€ d’achat de produits LEGO® en France Métropolitaine (Corse <br className={"d-none d-lg-block"}/>incluse). Conservez votre preuve d’achat pour participer. L’offre donne droit à des sets LEGO® attribués via des instants <br className={"d-none d-lg-block"}/>gagnants
                    et une chance de gagner par tirage au sort un séjour de prestige en Europe d’une valeur maximale <br className={"d-none d-lg-block"}/>de 10 000€. Voir Règlement du Jeu complet <a className={"underline"} href={"/file/Règlement.pdf"} target={"_blank"}>ici</a>. Visuels non contractuels.</p>

            </div>

            <div id={"homeStrate2"} className={"strate"}>
                <div className={"anchor"} id={"jeux"}/>
                <h2 className={"line yellow"}>RELèVE les défis<br className={"d-lg-none"}/> SPORTIFs LEGO<sup>®</sup> !</h2>
                {createCarouselGame()}
                <div className={"translation"}>
                    *Fast Runner = Coureur Rapide / Crazy Swim = Nage en Folie / BMX Freestyle = BMX Artistique.
                </div>
            </div>

            <div id={"homeStrate3"} className={"strate"}>
                <div className={"anchor"} id={"comment-participer"}/>
                <h2 className={"line white"}>Comment participer ?</h2>
                {createCarouselHowto()}
            </div>

            <div id={"homeStrate4"} className={"strate"}>
                <div className={"content_wrapper"}>
                    <p>En participant à cette opération, et sous réserve que <br className={"d-none d-lg-block"}/>ta participation soit valide, tu seras automatiquement <br className={"d-none d-lg-block"}/>inscrit au tirage au sort pour tenter de gagner un <b>séjour <br
                        className={"d-none d-lg-block"}/>prestige en Europe</b> d’une valeur maximale de 10 000€.</p>
                    <p><b>Petite astuce :</b> plus tu télécharges de preuves d’achat <br className={"d-none d-lg-block"}/>éligibles sur ton compte, plus tu auras de chances <br className={"d-none d-lg-block"}/>de gagner !</p>
                    <p>Rendez-vous <br className={"d-lg-none"}/>le 21 août prochain !</p>
                </div>
                <Link className={"cta red"} to={"/connexion"}  onClick={() => window.mixpanelhandler.track("Sign in Selected",{"Result count" : 1})}>Je m’inscris/Je me connecte</Link>
            </div>

            <div id={"homeStrate5"} className={"strate"}>
                <div className={"anchor"} id={"les-surprises"}/>
                <h2 className={"line yellow"}>LES SURPRISES LEGO®</h2>
                <p>
                    <b>Laisse ta créativité et ton imagination <br className={"d-lg-none"}/>s’exprimer et amuse-toi <br className={"d-none d-lg-block"}/>à construire ces trois <br className={"d-lg-none"}/>sets LEGO® emblématiques !</b><br/>
                    Ils seront parfaits pour décorer ton intérieur.
                </p>
                {createCarouselDotation()}
            </div>

            <div id={"homeStrate6"} className={"strate"}>
                <div>
                    Tente de <br className={"d-lg-none"}/>
                    gagner un séjour <br className={"d-lg-none"}/>
                    de prestige <br className={"d-lg-block d-none"}/>en Europe <br className={"d-lg-none"}/>
                    d’une valeur de 10 000€ !
                </div>
                <p>
                    Au programme, les hôtels les plus <br className={"d-lg-none"}/>
                    beaux <br className={"d-lg-block d-none"}/>de ta destination et les <br className={"d-lg-none"}/>
                    expériences les <br className={"d-lg-block d-none"}/>plus inoubliables !
                </p>
                <Link className={"cta red"} to={"/connexion"} onClick={() => window.mixpanelhandler.track("Sign in Selected",{"Result count" : 1})}>Je m’inscris/Je me connecte</Link>
            </div>

            <Contact/>
        </>
    );
};


export default Home;