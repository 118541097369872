import React, {useCallback, useEffect, useRef, createRef, useImperativeHandle, useState} from "react";
import {useSetState} from "react-use";

import {Modal} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {getDotation} from "../../utils/utils";

const Player = React.forwardRef((props, ref) => {

    return(
        <div id={"player"} ref={ref} className={`step${props.frame}`}></div>
    )
})

const GameUi = (props) => {

    const {points, time, speed} = props;

    return(
        <div id={"game_ui"}>
            <div className={"score"}>{points} M</div>
            <div className={"time"}>{time} s</div>
        </div>
    )
}

const Background = React.forwardRef((props, ref) => {

    const {position} = props
    const [playerList, setPlayerList] = useState([])


    useEffect(() => {
        const otherPlayer = [
            "wheel",
            "run",
            "skate",
        ]
        const otherPlayerCount = 20
        const maxDistance = 40000
        let type,prev_type,position

        for(let i=1 ; i <= otherPlayerCount ; i++){

            setPlayerList( (oldPlayerList) => {

                do{
                    type = Math.floor(Math.random() * 3);
                }while(prev_type === type)

                let position = (maxDistance / otherPlayerCount) * i
                prev_type = type

                return ([...oldPlayerList, <div className={`otherPlayer ${otherPlayer[type]}`} id={`otherPlayer${i}`} style={{left: `${position}px`}}/>])
            })
        }

    }, [])

    return (
        <div id={"run_background"} ref={ref}>
            <div className={"sky"} style={{
                transform: `translateX(-${position.sky}px)`
            }}/>
            <div className={"city"} style={{
                transform: `translateX(-${position.city}px)`
            }}/>
            <div className={"road"} style={{
                transform: `translateX(-${position.road}px)`
            }}>
                {playerList}
            </div>

        </div>
    )

})

const FastRunner = () => {

    //handle pa id if exist
    const [paId, setPaId] = useState(null)
    const [dotation, setDotation] = useState(null)
    const location = useLocation()

    useEffect(() => {
        if(location?.state?.pa_id) {
            setPaId(location.state.pa_id)
        }
    }, []);

    //state
    const initialState = {
        isRunning: false,
        speed: 0,
        position: {
            sky: 0,
            city: 0,
            road: 0
        },
        distance: 0,
        time: 30,
        timeStart: null,
        loop: {
            then : 0,
            playerPosition : 1
        }
    }
    const [gameState, setGameState] = useSetState(initialState)

    //config
    const config = {
        /*addSpeedStep : 150,
        decreaseSpeedRatio : 20,*/
        addSpeedStep : 250,
        decreaseSpeedRatio : 40,
        stepForFrameChange : 100,
        totalTime : 30
    }

    //keypress state
    const keyPress = useRef(false)

    //Modal state
    const [showStartModal, setShowStartModal] = useState(true);
    const [showEndModal, setShowEndModal] = useState(false);

    //ref
    const requestRef = useRef();
    const gameRef = useRef();
    const playerRef = useRef();

    const toggleFullScreen = (state) => {

        if ("ontouchstart" in document.documentElement){
            let elem = document.getElementById("fullscreenHandler")

            if(state){
                if (document.exitFullscreen) {
                    elem.requestFullscreen()
                }

            }else{

                if (document.exitFullscreen) {
                    document.exitFullscreen().catch((err) => console.error(err));
                }

            }
        }

    }

    //requestFrame
    const advanceStep = useCallback(() => {

        //move decor and handle speed decrease
        setGameState((oldState) => {

            //save start time of the loop
            const now = Date.now()
            const timer = config.totalTime - Math.floor((now - oldState.timeStart) / 1000)

            //update timer
            if( timer <= 0){
                //setShowEndModal(true)

                return ({
                    ...oldState,
                    time : 0,
                    isRunning : false
                })
            }

            //si pas de speed on fait rien
            if(oldState.speed <= 0) {
                return ({
                    ...oldState,
                    time : timer,
                })
            }

            //sinon on avance progressivement
            const step = Math.ceil(oldState.speed / (config.decreaseSpeedRatio/* - oldState.speed*/) )
            const road_step = step;
            const city_step = step * 0.66;
            const sky_step = step * 0.33;

            //move player frame
            let then = oldState.loop.then
            let playerPosition = oldState.loop.playerPosition

            if(then + step >= config.stepForFrameChange){
                then = 0
                playerPosition = playerPosition === 8 ? 1 : playerPosition+1
            }

            return({
                speed : oldState.speed - step,
                distance : Math.ceil(oldState.distance + (step / 200)),
                position : {
                    sky : oldState.position.sky + sky_step,
                    city : oldState.position.city + city_step,
                    road : oldState.position.road + road_step
                },
                loop : {
                    then : then + step,
                    playerPosition: playerPosition
                },
                time : timer
            })
        })

        //animate character
        requestRef.current = requestAnimationFrame(advanceStep);

    },[]);

    //game loop
    useEffect(() => {

        const stop = () => {
            requestRef.current && cancelAnimationFrame(requestRef.current);
            toggleFullScreen(false)

            //if timer w=0 then the game is over
            if(gameState.time <= 0) {
                console.log("game ending")
                console.log(gameState)
                handleGameEnding()
            }

            //window.mixpanelhandler.track("Game completed",{"Score" : gameState.points, "Game Name" : "The race for gold coins"})
        }

        if (gameState.isRunning)
            requestRef.current = requestAnimationFrame(advanceStep);
        else
            stop();

    }, [gameState.isRunning])

    //set up key event
    const handlePlayerKeyDown = (e) => {
        if(e.which === 32 && !keyPress.current) {
            console.log(keyPress.current)
            keyPress.current = true
            e.preventDefault()
            addSpeed()
        }
    }

    useEffect(() => {
        document.addEventListener('keydown',handlePlayerKeyDown);
        document.addEventListener('keyup', () => keyPress.current = false);
    },[])

    //start game
    const startGame = (team) => {

        window.mixpanelhandler.track("Run",{"Let Go" : 1})

        //setfullScreen
        toggleFullScreen(true)

        setShowStartModal(false)
        setGameState({
            isRunning: true,
            timeStart : Date.now()
        })
    }

    //reset game
    const resetGame = () => {

        window.mixpanelhandler.track("Run",{"Game Replay" : 1})

        setGameState(initialState)

        setShowEndModal(false);
        setShowStartModal(true);
    }

    const addSpeed = (e = null) => {

        if(e !== null && e.targetTouches && e.targetTouches.length > 1)
            return;

        setGameState((oldState) => {
            return({
                speed: oldState.speed + config.addSpeedStep
            })
        } )
    }

    const handleGameEnding = () => {
        toggleFullScreen(false)

        //play from account
        if(paId !== null)
            getDotation(paId,"run")
                .then(response => response.json())
                .then(json => {
                    if (json.success === true) {
                        //save dotation
                        setDotation(json.data.dotation)
                        //display end modal with link to dotation
                        setShowEndModal(true);
                        window.mixpanelhandler.track("Run",{"Game Complete" : 1})
                    }else{

                    }
                });
        else
            setShowEndModal(true)
    }

    return(
        <div id={"fullscreenHandler"}>

            <Modal show={showStartModal} onHide={() => setShowStartModal(false)} id={"modalStartRun"} className={"game_modal start"} centered={true} backdrop={"static"}>
                <Modal.Body>
                    <Link id={"modal_close"} to={paId ? "/mon-compte":"/"}/>
                    <h2 className={"line yellow"}>Cours le plus vite possible<br/> pour effectuer la plus grande <br/>distance en 30 secondes !</h2>
                    <p className={"mobile_instruction"}>Tape sur l’écran de ton smartphone pour courir le plus vite possible.</p>
                    <p className={"desktop_instruction"}>Utilise la barre d’espace de ton clavier ou clique<br/> avec ta souris pour courir le plus vite possible.</p>
                    <div className={"translation"}>Fast Runner = Coureur Rapide.</div>
                    <button className={"cta yellow"} onClick={startGame}>C’est parti !</button>
                </Modal.Body>
            </Modal>

            <Modal show={showEndModal} onHide={() => setShowEndModal(false)} id={"modalEndRun"} className={"game_modal end"} centered={true} backdrop={"static"} >
                <Modal.Body>
                    <h2>Tu as parcouru</h2>
                    <div className={"score"}><span>{gameState.distance}</span>M</div>

                    {dotation === null &&
                        <>
                            <div className={"modal_block"} id={"modal_block_restart"}>
                                <p>Tu penses pouvoir faire mieux ?</p>
                                <button className={"cta red"} onClick={() => {
                                    window.mixpanelhandler.track("Run",{"I train" : 1})
                                    resetGame()
                                }}>Je continue de m’entraîner</button>
                            </div>
                            <div className={"modal_block"} id={"modal_block_login"}>
                                <p>Tu te sens prêt à tenter ta chance ?</p>
                                <Link to={"/connexion"} className={"cta red"}>Je m’inscris/je me connecte</Link>
                            </div>
                        </>
                    }

                    {dotation !== null &&
                        <div className={"modal_block"} id={"modal_block_restart"}>
                            <Link to={"/dotation"} state={{dotation: dotation}} className={"cta red"}>Je découvre mon gain</Link>
                        </div>
                    }

                </Modal.Body>
            </Modal>

            <div className={"mobile_landscape_switcher"}/>
            <div id={"game_run_wrapper"} className={"game_wrapper"} ref={gameRef} onMouseDown={addSpeed}>
            <GameUi points={gameState.distance} time={gameState.time} speed={gameState.speed}/>
                <Background position={gameState.position}/>
                <Player ref={playerRef} frame={gameState.loop.playerPosition}/>
                <div id={"mobile_game_control"} onTouchStartCapture={(e) => addSpeed(e)}/>
            </div>
        </div>
    )
}

export default FastRunner