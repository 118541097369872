import {RouterProvider} from "react-router-dom";
import router from "./component/routes/Router";
import history from "./component/routes/history";

import React from "react";
import {UserContext, UserProvider} from "./component/context/UserContext";

function App() {

    return (
        <UserProvider>
            <RouterProvider router={router} history={history}/>
        </UserProvider>
    );
}

export default App;
