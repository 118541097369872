import {useEffect} from "react";
import {Helmet} from "react-helmet";

const Retailer = () => {

    const retailerList = [
        "kingjouet",
        "joueclub",
        "cdiscount",
        "fnac",
        "lagranderecre",
        "auchan"
    ]

    const queryString = window.location.search;
    const retailer = sessionStorage.getItem("retailer")

    useEffect(() => {

        if(queryString){
            const urlParams = new URLSearchParams(queryString);

            if(urlParams.has('r')){
                const retailer = urlParams.get('r')

                if(retailerList.includes(retailer))
                    sessionStorage.setItem("retailer",retailer)
                else
                    sessionStorage.removeItem("retailer")

                //reload page
                window.location = window.location.href.split("?")[0];
            }
        }

        //check retailer to insert good cookie script


    }, []);

    return(
        <Helmet>
            {retailer !== "joueclub" && <script id="usercentrics-cmp" src="https://app.usercentrics.eu/browser-ui/latest/bundle.js" data-settings-id="LSH6B8FmvKVjPI" async></script>}
            {retailer === "joueclub" && <script id="joueclub-cookie" src={process.env.PUBLIC_URL + "/lib/jccookie.js"}></script>}
        </Helmet>
    )
}

export default Retailer