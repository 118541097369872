import {useForm} from "react-hook-form";
import React, {useContext, useRef, useState} from "react";
import * as Helper from "../../form/form_inscription_helper";
import * as Pattern from "../../form/form_pattern_helper";
import CustomFieldText from "../../form/CustomFieldText";
import PageTitle from "../_title";
import {Link, useNavigate} from "react-router-dom";
import Contact from "../contact";
import {UserContext} from "../../context/UserContext";
import GenericModal from "../../_generic_modal";

const Connexion = () => {

    //generic modal state
    const [showModal, setShowModal] = useState(false)
    const [modalContent, setModalContent] = useState({
        "title" : "Information",
        "content" : "coucou"
    })

    //form validation handle
    const {register, handleSubmit, formState: { errors } } = useForm()
    const recaptchaRef = useRef(null)
    const submitRef = useRef(null)

    const {login} = useContext(UserContext)
    const navigate = useNavigate()

    const handleFormSubmit = (data) => {

        let formData = new FormData();
        formData.append('form', JSON.stringify(data));
        formData.append("action", "login");
        //formData.append("recaptcha", recaptcha);

        fetch(process.env.REACT_APP_API_URL + '/index.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {
                    window.mixpanelhandler.track("Sign in_selected",{"Result count" : 1})

                    login(json.data.jwt)
                    navigate("/mon-compte")
                } else {

                    let error = ""

                    switch(json.message){
                        case  "#recaptcha" :
                            error = "Veuillez cocher la case \"Je ne suis pas un robot\" pour continuer."
                            break;

                        case "#unknow_account" :
                            error ="Compte inconnu, veuillez vérifier vos identifiants pour continuer"
                            break;

                        default :
                            alert(json.message);
                    }

                    setModalContent({
                        title : "Information",
                        content : error
                    })
                    setShowModal(true)
                    //submitRef.current.removeAttribute("disabled");
                    //window.grecaptcha.reset();
                }

            });

    }

    return(
        <div className={"connexion_wrapper"}>
            <PageTitle/>

            <div className={"strate first"} id={"connexionStrate"}>
                <div>
                    <h1 className={"line yellow"}>connecte toi</h1>
                    <p>Connecte-toi afin de télécharger tes preuves<br className={"d-lg-none"}/> d’achat <br className={"d-lg-block d-none"}/><b>pour tenter ta chance !</b></p>

                    <form onSubmit={handleSubmit(handleFormSubmit)}>

                        <CustomFieldText name="login" placeholder="E-mail" register={register('login', {
                            required: Helper.EmptyErrorMessages.mail,
                            pattern: {value: Pattern.emailPattern, message: Helper.InvalidErrorMessages.mail},
                        })} error={errors.login && errors.login.message}/>

                        <CustomFieldText name="password" placeholder="Mot de passe" type="password" register={register('password', {
                            required: Helper.EmptyErrorMessages.password
                        })} error={errors.password && errors.password.message}/>

                        <button className={"cta red"} type="submit">Je me connecte</button>
                    </form>

                    <div id={"link_wrapper"}>
                        <Link to={"/inscription"}>Créer un compte</Link>
                        <Link to={"/mot-de-passe-oublie"}>Mot de passe oublié ?</Link>
                    </div>
                </div>
            </div>

            <Contact/>
            <GenericModal title={modalContent.title} content={modalContent.content} handleClose={() => setShowModal(false)} show={showModal}/>
        </div>
    )
}

export default Connexion