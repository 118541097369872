import {useForm} from "react-hook-form";
import React, {useContext, useRef, useState} from "react";
import * as Helper from "../../form/form_inscription_helper";
import * as Pattern from "../../form/form_pattern_helper";
import CustomFieldText from "../../form/CustomFieldText";
import PageTitle from "../_title";
import {Link, useNavigate} from "react-router-dom";
import Contact from "../contact";
import {UserContext} from "../../context/UserContext";
import ReCAPTCHA from "react-google-recaptcha";
import GenericModal from "../../_generic_modal";

const LostPassword = () => {

    //generic modal state
    const [showModal, setShowModal] = useState(false)
    const [modalContent, setModalContent] = useState({
        "title" : "Information",
        "content" : "coucou"
    })

    //submit form handle
    const {register, handleSubmit, formState: { errors } } = useForm()
    const recaptchaRef = useRef(null)
    const submitRef = useRef(null)

    const [showForm, setShowForm] = useState(true)

    const handleFormSubmit = (data) => {

        submitRef.current.setAttribute("disabled", "disabled");

        //controle recaptcha
        document.getElementById("recaptcha_error").innerHTML = " ";
        const recaptcha = recaptchaRef.current.getValue();

        //check recaptcha
        if (recaptcha.length === 0) {
            document.getElementById("recaptcha_error").innerHTML = Helper.EmptyErrorMessages.recaptcha;
            submitRef.current.removeAttribute("disabled");
            return false;
        }

        let formData = new FormData();
        formData.append('form', JSON.stringify(data));
        formData.append("recaptcha", recaptcha);
        formData.append("action", "lost_password");

        fetch(process.env.REACT_APP_API_URL + '/index.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {

                    setModalContent({
                        title : "Information",
                        content : "Vous allez recevoir un email contenant votre nouveau mot de passe"
                    })

                } else {

                    let error = ""

                    switch(json.message){
                        case  "#recaptcha" :
                            error = "Veuillez cocher la case \"Je ne suis pas un robot\" pour continuer."
                            break;

                        case "#unknow_account" :
                            error ="Compte inconnu, veuillez vérifier vos identifiants pour continuer"
                            break;

                        default :
                            alert(json.message);
                    }

                    setModalContent({
                        title : "Information",
                        content : error
                    })

                    submitRef.current.removeAttribute("disabled");
                }

                setShowModal(true)
                window.grecaptcha.reset();

            });

    }

    return(
        <div className={"lost_password_wrapper"}>
            <PageTitle/>

            <div className={"strate first"} id={"connexionStrate"}>
                <div>
                    <h1 className={"line yellow"}>Mot de passe oublié</h1>
                    <p>Renseigne ton adresse e-mail et nous t'enverrons <br className={"d-lg-none"}/>un nouveau mot de passe pour te connecter</p>

                    {showForm &&
                        <form onSubmit={handleSubmit(handleFormSubmit)}>

                            <CustomFieldText name="mail" placeholder="E-mail" register={register('mail', {
                                required: Helper.EmptyErrorMessages.mail,
                                pattern: {value: Pattern.emailPattern, message: Helper.InvalidErrorMessages.mail},
                            })} error={errors.login && errors.login.message}/>

                            {/* recaptcha */}
                            <div className="text-center" id={"recaptcha_wrapper"}>
                                <ReCAPTCHA className="d-inline-block" sitekey="6LevKCQhAAAAAErcCcwZpbzQM4R_JcNDUrml4-2b" ref={recaptchaRef}/>
                                <div className="error text-center" id="recaptcha_error">&nbsp;</div>
                            </div>

                            <button className={"cta red"} type="submit" ref={submitRef}>Je valide</button>
                        </form>
                    }

                    {!showForm && <div id={"message_wrapper"}>Un email contenant votre nouveau mot de passe vient de vous être envoyé</div>}

                    <div id={"link_wrapper"}>
                        <Link to={"/connexion"}>Me connecter</Link>
                        <Link to={"/inscription"}>Créer un compte</Link>
                    </div>
                </div>
            </div>

            <Contact/>
            <GenericModal title={modalContent.title} content={modalContent.content} handleClose={() => setShowModal(false)} show={showModal}/>
        </div>
    )
}

export default LostPassword