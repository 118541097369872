import React, {useContext, useEffect} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import {UserContext} from "../context/UserContext";

const ProtectedRoute = (props) => {

    const { user } = useContext(UserContext);

    if(!user.connected)
        window.location.href="/connexion"
    else
        return(props.children)
}

export default ProtectedRoute