import {useForm} from "react-hook-form";
import React, {useContext, useRef, useState} from "react";
import * as Helper from "../../form/form_inscription_helper";
import * as Pattern from "../../form/form_pattern_helper";
import CustomFieldText from "../../form/CustomFieldText";
import PageTitle from "../_title";
import CustomComboBox from "../../form/CustomComboBox";
import CustomUploadField from "../../form/CustomUploadField";
import ReCAPTCHA from "react-google-recaptcha";
import {UserContext} from "../../context/UserContext";

const Inscription = () => {

    const {register, handleSubmit, formState: { errors }, setError, setValue, clearErrors } = useForm()
    const recaptchaRef = useRef(null)
    const submitRef = useRef(null)

    const [file, setFile] = useState("")

    const {login} = useContext(UserContext)

    function setFilename(event,fieldname,setFileHandle) {

        if (event.target.files[0] !== undefined && Helper.checkIsUploadable(event.target.files[0])) {
            setValue(fieldname, event.target.files[0].name);
            setFileHandle(event.target.files[0]);
            clearErrors(fieldname);
        } else if (!Helper.checkIsUploadable(event.target.files[0])) {
            setError(fieldname, "filesize", Helper.InvalidErrorMessages.filesize);
        }
    }

    const handleFormSubmit = (data) => {

        submitRef.current.setAttribute("disabled", "disabled");

        //controle recaptcha
        document.getElementById("recaptcha_error").innerHTML = " ";
        const recaptcha = recaptchaRef.current.getValue();

        //check recaptcha
        if (recaptcha.length === 0) {
            document.getElementById("recaptcha_error").innerHTML = Helper.EmptyErrorMessages.recaptcha;
            submitRef.current.removeAttribute("disabled");
            return false;
        }

        let formData = new FormData();
        formData.append('form', JSON.stringify(data));
        formData.append("recaptcha", recaptcha);
        formData.append("ticket", file);
        formData.append("action", "signup");

        fetch(process.env.REACT_APP_API_URL + '/index.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {
                    window.mixpanelhandler.track("Registration_selected",{"Result count" : 1})
                    //connect user and redirect
                    login(json.data.jwt)
                } else {

                    switch(json.message){
                        case  "#recaptcha" :
                            alert("Veuillez cocher la case \"Je ne suis pas un robot\" pour continuer.");
                            break;

                        case "#already_participate" :
                            alert("L'opération est limité à une participation par personne.");
                            break;

                        case "#forbidden_cp" :
                            alert("Cette opération est limitée aux résidents de France Métropolitaine et de Corse uniquement !");
                            break;

                        default :
                            alert(json.message);
                    }

                    submitRef.current.removeAttribute("disabled");
                    window.grecaptcha.reset();
                }

            });

    }

    return(
        <div className={"inscription_wrapper"}>
            <PageTitle/>
            <div className={"strate"} id={"inscriptionStrate"}>
                <div>
                    <h1 className={"line yellow left"}>inscris-toi</h1>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <div>
                            <input type={"hidden"} name={"retailer"} id={"retailer"} value={sessionStorage.getItem("retailer")??""} {...register("retailer")}/>
                            <CustomComboBox name="civilite" id="civilite" label={"Civilité*"} placeholder={"Sélectionner"}
                                            options={["Madame", "Monsieur", "Ne souhaite pas se prononcer"]}
                                            register={register("civilite", {
                                                required: Helper.EmptyErrorMessages.civilite
                                            })}
                                            error={errors.civilite && errors.civilite.message}/>

                            <CustomFieldText name="prenom" label="Prénom*" register={register('prenom', {
                                required: Helper.EmptyErrorMessages.prenom,
                                pattern: {value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.prenom},
                                minLength: {value: 2, message: Helper.InvalidErrorMessages.prenom}
                            })} error={errors.prenom && errors.prenom.message}/>
                            <CustomFieldText name="nom" label="Nom*" register={register('nom', {
                                required: Helper.EmptyErrorMessages.nom,
                                pattern: {value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.nom},
                                minLength: {value: 2, message: Helper.InvalidErrorMessages.nom}
                            })} error={errors.nom && errors.nom.message}/>

                            <CustomFieldText name="mail" label="Adresse e-mail*"
                                             register={
                                                 register("mail", {
                                                     required: true,
                                                     pattern: Pattern.emailPattern,
                                                     validate: {
                                                         domain: (value) => Helper.checkMailDomain(value)
                                                         /*confirm : checkMailConfirm*/
                                                     }
                                                 })}
                                             error={[
                                                 errors.mail && errors.mail.type === "required" && Helper.EmptyErrorMessages.mail,
                                                 errors.mail && errors.mail.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                                 errors.mail && errors.mail.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                                 errors.mail && errors.mail.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                                             ]}/>

                            <CustomFieldText name="mail_confirm" label="Confirmation de l'adresse e-mail*"
                                             register={
                                                 register("mail_confirm", {
                                                     required: true,
                                                     pattern: Pattern.emailPattern,
                                                     validate: {
                                                         domain: (value) => Helper.checkMailDomain(value),
                                                         confirm: Helper.checkMailConfirm
                                                     }
                                                 })}
                                             error={[
                                                 errors.mail_confirm && errors.mail_confirm.type === "required" && Helper.EmptyErrorMessages.mail,
                                                 errors.mail_confirm && errors.mail_confirm.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                                 errors.mail_confirm && errors.mail_confirm.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                                 errors.mail_confirm && errors.mail_confirm.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                                             ]}/>

                            <CustomFieldText name="password" label="Mot de passe*" type={"password"}
                                             register={register("password", {required: Helper.EmptyErrorMessages.password})}
                                             error={errors.password && errors.password.message}
                            />

                            <CustomUploadField
                                label={"Preuve d’achat <br class='d-none d-lg-block'/><div>(ticket de caisse ou facture)</div><span>*</span>"}
                                warning={"*Le fichier ne doit pas dépasser les 7MO et doit être au format <br class='d-lg-none'/>JPEG,<br class='d-none d-lg-block'/> JPG, PNG ou PDF."}
                                name={"ticket"}
                                onchangeHandler={(e) => setFilename(e, "ticket", setFile)}
                                register={register("ticket", {required: Helper.EmptyErrorMessages.ticket})}
                                error={errors.ticket && errors.ticket && errors.ticket.message}
                            />
                        </div>
                            <div>

                            <div className={"optin_container"}>

                                <div id={"optin_age_wrapper"} className="checkbox_wrapper form_row_wrapper">
                                    <input type="checkbox" name="optin_age" id="optin_age" {...register("optin_age", {required: Helper.EmptyErrorMessages.optin_age})}/>

                                    <label htmlFor="optin_age">
                                        Je certifie sur l’honneur que je suis âgé(e) de 18 ans ou plus lors de la création de mon compte.<span>*</span>
                                    </label>

                                    <div className="error text-center">{errors.optin_age && errors.optin_age.message}</div>
                                </div>

                                <div id={"optin_reglement_wrapper"} className="checkbox_wrapper form_row_wrapper">
                                    <input type="checkbox" name="optin_reglement" id="optin_reglement" {...register("optin_reglement", {required: Helper.EmptyErrorMessages.optin_reglement})}/>

                                    <label htmlFor="optin_reglement">
                                        Je reconnais avoir pris connaissance du <a href={"/file/Règlement.pdf"} target={"_blank"} className={"underline"}>Règlement du Jeu</a> de l’opération et en accepter les termes.<span>*</span>
                                    </label>
                                    <div className="error text-center">{errors.optin_reglement && errors.optin_reglement.message}</div>
                                </div>


                            </div>

                            <div className={"mandatory"}>
                                Tous les champs marqués d’un astérisque (*) sont obligatoires. Toute demande incomplète ne pourra être validée et traitée.
                            </div>

                            <div id={"rgpd_text_wrapper"}>

                                <div id={"optin_tlc_wrapper"} className="checkbox_wrapper form_row_wrapper">
                                    <input type="checkbox" name="optin_tlc" id="optin_tlc" {...register("optin_tlc")}/>
                                    <label htmlFor="optin_tlc">
                                        Oui, je souhaite être contacté par TLC Worldwide à des fins d'enquêtes de satisfaction, d'information sur nos offres promotionnelles et d'études de marché par e-mail.
                                    </label>
                                </div>

                                <p>Conformément à la loi Informatique et libertés du 6 janvier 1978 dans sa dernière version en vigueur ainsi que le Règlement UE 2016/679 du 27 avril 2016 publié au Journal Officiel de l’Union Européenne le 4 mai 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, les informations recueillies sont destinées à TLC Marketing en sa qualité de responsable de traitement.</p>
                                <p>TLC Marketing prend les mesures conformes à l’état de l’art afin d’assurer la sécurité et la confidentialité des données, conformément aux dispositions légales précitées. Vos informations seront conservées
                                    jusqu’au 31/12/2027, au plus tard conformément à la réglementation si vous avez coché une case opt-in du formulaire de participation. Autrement, vos données seront supprimées à partir du 31/03/2025. Vous
                                    bénéficiez d’un droit d’accès, de rectification, d’opposition, de limitation, d’effacement, de portabilité et d’information concernant les données personnelles vous concernant. Afin d’exercer ces droits, nous
                                    vous remercions de bien vouloir adresser votre demande auprès de TLC Marketing France par e-mail à <a href={"mailto:francedata@tlcrewards.com?subject=Mes%20données%20personnelles"} className={"underline"}>francedata@tlcrewards.com</a> ou par courrier à l’adresse suivante : TLC Marketing
                                    France – Mes données personnelles, 92 avenue de Wagram, 75017 Paris.</p>

                            </div>

                            {/* recaptcha */}
                            <div className="text-center" id={"recaptcha_wrapper"}>
                                <ReCAPTCHA className="d-inline-block" sitekey="6LevKCQhAAAAAErcCcwZpbzQM4R_JcNDUrml4-2b" ref={recaptchaRef}/>
                                <div className="error text-center" id="recaptcha_error">&nbsp;</div>
                            </div>

                            {/* server error */}
                            <div className="text-center">
                                <div className={"error text-center mb-3"} id={"global_error"}>
                                    {/*error*/}
                                </div>
                                <input type={"submit"} className={"cta red"} id={"submit_cta"} ref={submitRef} value={"Je valide"}/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Inscription