import {createBrowserRouter} from "react-router-dom";

import Layout from "../_Layout"
import ErrorPage from "../pages/404";

import Home from "../pages/home";
import MentionsLegales from "../pages/mentions-legales";
import Faq from "../pages/faq";
import OperationTerminee from "../pages/operation-terminee";

import {isOpeStartRedirect, isOpeStopRedirect} from "../loader/redirectLoader";

import ProtectedRoute from "./ProtectedRoute";

import Connexion from "../pages/compte/Connexion";
import Inscription from "../pages/compte/Inscription";
import MonCompte from "../pages/compte/MonCompte";
import LostPassword from "../pages/compte/LostPassword";
import LogOffRoute from "./logOffRoute";
import Bmx from "../game/bmx/Bmx";
import FastRunner from "../game/run/FastRunner";
import FlappyGame from "../game/swim/FlappyGame";
import Dotation from "../pages/dotation";


const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        errorElement: <ErrorPage />,
        children : [
            {
                path: "/",
                element : <Home/>,
                loader : isOpeStartRedirect
            },
            {
                path : "/informations-legales",
                element : <MentionsLegales/>,
                loader : isOpeStartRedirect
            },
            {
                path : "/faq",
                element : <Faq/>,
                loader : isOpeStartRedirect
            },
            {
                path : "/operation-terminee",
                element : <OperationTerminee/>,
                loader : isOpeStartRedirect
            },
            {
                path : "/dotation",
                element : <ProtectedRoute><Dotation/></ProtectedRoute>,
            }/*,
            {
                path : "/jeu",
                element : <CoinCatcher/>
            }*/,
            //--------------------------
            //  COMPTE
            //--------------------------
            {
                path : "/connexion",
                element : <LogOffRoute><Connexion/></LogOffRoute>,
                loader : isOpeStopRedirect
            },
            {
                path : "/inscription",
                element : <LogOffRoute><Inscription/></LogOffRoute>,
                loader : isOpeStopRedirect
            },
            {
                path : "/mon-compte",
                element : <ProtectedRoute><MonCompte/></ProtectedRoute>,
                loader : isOpeStopRedirect
            },
            {
                path : "/mot-de-passe-oublie",
                element : <LogOffRoute><LostPassword/></LogOffRoute>,
                loader : isOpeStopRedirect
            },
            //--------------------------
            //  COMPTE
            //--------------------------
            {
                path : "/jeu/bmx",
                element : <Bmx/>
            },
            {
                path : "/jeu/fast-runner",
                element : <FastRunner/>
            },
            {
                path : "/jeu/crazy-swim",
                element : <FlappyGame/>
            }
        ]
    },
]);

export default router